import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { colors } from "../common/themeStyle"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { SEO } from "../components/seo"
import { useLocation } from "@reach/router"
import media from "styled-media-query"

const NotFoundPage = () => {
  const windowLocation = useLocation()
  return (
    <Layout>
      <SEO pageTitle="NOT FOUND" pagePath={windowLocation.pathname} />
      <NotFoundWrapper>
        <NotFoundImageWrapper>
          <StaticImage src="../images/kumachon_404.png" alt="kumachon 404" />
        </NotFoundImageWrapper>

        <NotFoundText>
          申し訳ありません。
          <LessThanMediumBreake />
          お探しのページが見つかりませんでした。
        </NotFoundText>
        <ErrorText>
          <div>
            URLが間違っているか、
            {/* <LessThanMediumBreake /> */}
            ページが存在しません。
          </div>
          <div>もう一度アドレスをご確認ください。</div>
        </ErrorText>
        <ToTopPageButton
          onClick={() => {
            navigate("/")
          }}
        >
          TOPページへ戻る
        </ToTopPageButton>
      </NotFoundWrapper>
    </Layout>
  )
}

export default NotFoundPage

const NotFoundWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem 0;
`

const NotFoundImageWrapper = styled.div`
  margin: 0 auto;
  width: 300px;
  padding: 20px;
  margin-bottom: 40px;
  ${media.lessThan("medium")`
  width: 150px;
  padding: 10px;
  `}
`
const NotFoundText = styled.h3`
  ${media.lessThan("medium")`
  font-size: 17px;
  `}
`
const ErrorText = styled.div`
  margin-bottom: 80px;
  font-size: 16px;
  line-height: 24px;
  ${media.lessThan("medium")`
  margin-bottom: 50px;
  font-size: 14px;
  `}
`
const ToTopPageButton = styled.button`
  text-decoration: none;
  width: 100%;
  max-width: 500px;
  height: 60px;
  border: 0px;
  margin: 0 auto;
  background-color: ${colors.yellow};
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  letter-spacing: 0.8px;
  color: ${colors.black};
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  margin-bottom: 100px;
`
const LessThanMediumBreake = styled.br`
  ${media.greaterThan("medium")`
    display: none;
  `}
`
